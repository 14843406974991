import { useDispatch } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import "../../../assets/styles/takeQuiz/quizTakeNavbar.css";
import "../../../assets/styles/index.css";
import { useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../state";

export default function QuizTakeNavbar() {
  const quizGeneralSettings = useSelector((state) => state.quizGeneralSettings);
  const [progressComplete, setProgressComplete] = useState("0%");
  const quizProgress = useSelector((state) => state.quizProgress);
  const completeQuestion = useSelector((state) => state.completeQuestion);
  const takeQuizQuestion = useSelector((state) => state.takeQuizQuestion);
  const dispatch = useDispatch();
  const { setQuizProgress, setQuizGeneralSettings, setIsTimeOutForceSubmit } =
    bindActionCreators(actionCreators, dispatch);
  const [questionTypes, setQuestionTypes] = useState("");
  const timeRef = useRef(null);

  const getRemainingMinuteSecond = () => {
    const time =
      new Date().getTime() - parseInt(sessionStorage.getItem("quizStartTime"));
    let seconds = Math.floor(time / 1000);
    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    minutes = quizGeneralSettings?.minutes - minutes - 1;
    seconds = 60 - seconds - 1;
    return { seconds: seconds, minutes: minutes };
  };

  const [remainingTimingMin, setRemainingTimingMin] = useState(
    getRemainingMinuteSecond().minutes,
  );
  const [remainingTimingSec, setRemainingTimingSec] = useState(
    getRemainingMinuteSecond().seconds,
  );

  useEffect(() => {
    let sec = remainingTimingSec;
    let min = remainingTimingMin;
    let interval = setInterval((e) => {
      sec = sec - 1;
      if (sec < 1) {
        min = min - 1;
        setRemainingTimingMin(min);
        sec = 59;
      }
      setRemainingTimingSec(sec);
      if (min === 0 && sec === 1) {
        clearInterval(interval);
      }
    }, 1000);
  }, []);

  window.onbeforeunload = (e) => {
    sessionStorage.setItem("sec", remainingTimingSec);
    sessionStorage.setItem("min", remainingTimingMin);
  };

  useEffect(() => {
    if (remainingTimingMin === 0 && remainingTimingSec === 1) {
      setTimeout(() => {
        setIsTimeOutForceSubmit(true);
        setRemainingTimingSec(0);
      }, 1000);
      // timeRef.current.style.visibility = "hidden";
    }
  }, [remainingTimingMin, remainingTimingSec]);

  useEffect(() => {
    switch (takeQuizQuestion?.question_type) {
      case "multiple_choice":
        setQuestionTypes("Multiple Choice Questions");
        break;
      case "essay":
        setQuestionTypes("Essay Questions");
        break;
      case "yes_no":
        setQuestionTypes("Yes or No Questions");
        break;
      case "true_false":
        setQuestionTypes("True or False Questions");
        break;
      case "multiple_response":
        setQuestionTypes("Multiple Response Questions");
        break;
      case "add_document":
        setQuestionTypes("Add Document");
        break;
      default:
        break;
    }
  }, [takeQuizQuestion]);

  const setTimeDifference = () => {
    const time = new Date().getTime() - sessionStorage.getItem("quizStartTime");
    let seconds = Math.floor(time / 1000);
    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    setRemainingTimingMin(quizGeneralSettings?.minutes - minutes - 1);
    setRemainingTimingSec(60 - seconds - 1);
    if (quizGeneralSettings?.minutes - (Math.floor(time / 1000 / 60) + 1) < 0) {
      setIsTimeOutForceSubmit(true);
    }
  };

  useEffect(() => {
    window.addEventListener("focus", setTimeDifference, false);
    return () => {
      window.removeEventListener("focus", setTimeDifference, false);
    };
  }, []);

  useEffect(() => {
    setTimeDifference();
  }, []);

  return (
    <div className="main-quiz-take-navbar">
      <div className="navbar-content">
        <div
          className="question-title"
          style={{
            userSelect: "none",
            WebkitUserSelect: "none",
            MozUserSelect: "none",
            msUserSelect: "none",
          }}
        >
          {questionTypes}
        </div>
        <div className="progress_container">
          <div className="progress">
            <span className="origin-progress-bar">
              <span
                className="total-questions"
                style={{
                  userSelect: "none",
                  WebkitUserSelect: "none",
                  MozUserSelect: "none",
                  msUserSelect: "none",
                }}
              >
                Question {completeQuestion} of{" "}
                {quizGeneralSettings?.selectnumberofquestion}
              </span>
              <div
                className="progress-bar"
                style={{ width: `${quizProgress}%` }}
              ></div>
            </span>
          </div>
          <span
            className="percent-complete"
            style={{
              userSelect: "none",
              WebkitUserSelect: "none",
              MozUserSelect: "none",
              msUserSelect: "none",
            }}
          >
            {`${quizProgress.toFixed(0)}%`}{" "}
          </span>
        </div>
        <span
          className="time-remaining"
          ref={timeRef}
          style={{
            userSelect: "none",
            WebkitUserSelect: "none",
            MozUserSelect: "none",
            msUserSelect: "none",
          }}
        >
          Time remaining: {remainingTimingMin} Min {remainingTimingSec} Sec{" "}
        </span>
      </div>
    </div>
  );
}
