import { create } from "zustand";

export const useFullscreenSubmitted = create((set) => ({
  isFullscreenSubmit: true,
  setIsFullscreenSubmit: () => set((state) => ({ isFullscreenSubmit: state })),
}));

export const setFullscreenSubmittedInSessionStorage = (isFullscreenSubmit) => {
  sessionStorage.setItem("isFullscreenSubmit", isFullscreenSubmit);
};

export const getFullscreenSubmittedFromSessionStorage = () => {
  return JSON.parse(sessionStorage.getItem("fullscreenSubmitted")) || false;
};

export const useFullscreenDialogOpen = create((set) => ({
  isFullscreenDialogOpen: false,
  setIsFullscreenDialogOpen: (state) =>
    set(() => ({ isFullscreenDialogOpen: state })),
  makeFalse: () => set(() => ({ isFullscreenDialogOpen: false })),
}));

export const setIsQuizSubmitted = (isQuizSumbitted) => {
  sessionStorage.setItem("isQuizSumbitted", isQuizSumbitted);
};

export const getIsQuizSubmitted = () => {
  return JSON.parse(sessionStorage.getItem("isQuizSumbitted")) || false;
};

export const setIsFirtRender = (isQuizSumbitted) => {
  sessionStorage.setItem("isFirstRender", isQuizSumbitted);
};

export const getIsFirstRender = () => {
  return JSON.parse(sessionStorage.getItem("isFirstRender")) || false;
};
