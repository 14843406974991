import React, { useEffect, useMemo, useRef, useState } from "react";
import "../assets/styles/quiztitle.css";
import QuizApi, { BACKEND_URL } from "../services/QuizApi";

import { Link, useNavigate, useParams } from "react-router-dom";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { actionCreators } from "../state";
import ContentEditable from "react-contenteditable";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import useGeoLocation from "react-ipgeolocation";
import { getHTMLtoText } from "../utils/generalHelp";
import {
  setIsQuizSubmitted,
  useFullscreenDialogOpen,
  setIsFirtRender,
} from "../contexts/FullscreenSubmitted";

export function decodeHtmlEntities(text) {
  const element = document.createElement("div");
  if (text) {
    element.innerHTML = text;
    return element.textContent;
  }
  return text;
}

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const PhoneInputInternational = ({
  value,
  setPhone,
  phoneFocus,
  setPhoneFocus,
}) => {
  const isValid = isPhoneValid(value);
  const [isFieldFocused, setIsFieldFocused] = useState(false);
  const location = useGeoLocation();

  const handleBlur = () => {
    setIsFieldFocused(false);
    setPhoneFocus(false);
  };

  const handleFocus = () => {
    setIsFieldFocused(true);
  };

  const handlePhoneChange = (phone) => {
    setPhone(phone);
    setPhoneFocus(true);
  };

  const phoneCountry = useMemo(() => {
    if (location.country) {
      let cPhone = location.country?.toLowerCase();
      return (
        <PhoneInput
          defaultCountry={`${cPhone}`}
          value={value}
          onChange={handlePhoneChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          required={true}
          forceDialCode={true}
        />
      );
    } else if (location.error) {
      <phoneInput
        defaultCountry="us"
        value={value}
        onChange={handlePhoneChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        required={true}
      />;
    }
  }, [location]);

  return (
    <div>
      {phoneCountry}
      {isFieldFocused && value.length > 8 && false && !isValid && (
        <div id="phone_error">
          Invalid phone number. Please check the format and try again.
        </div>
      )}
    </div>
  );
};
function QuizTitle() {
  const [passwordError, setPasswordError] = useState("");
  const [quiztitle, setQuizTitle] = useState([]);
  const [loading, setLoading] = useState(true);
  const [phone, setPhone] = useState("");
  const [error, setError] = useState(null);
  const { quizId } = useParams();
  const [isResponseError, setIsResponseError] = useState(false);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    emailaddress: "",
    phonenumber: phone,
    address: "",
    password: "",
    quizid: quizId,
  });

  let navigate = useNavigate();
  const [quizDetails, setQuizDetails] = useState({});
  const [quizSettings, setQuizsettings] = useState({});
  const [customScheduleMessage, setCustomScheduleMessage] = useState("");
  const detailContainerRef = useRef(null);
  const quizInfoRef = useRef(null);
  const [phoneFocus, setPhoneFocus] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isInstructionShown, setIsInstructionShown] = useState(false);
  const isTabChangeAllowed = useRef(false);
  const isBrowserSupportFullScreen = useRef(false);

  const setIsFullscreenDialogOpen = useFullscreenDialogOpen(
    (state) => state.setIsFullscreenDialogOpen,
  );

  const dispatch = useDispatch();
  const {
    setQuizGeneralSettings,
    setTakeQuizQuestion,
    setQuizBrowserSettings,
    setQuizResult,
    setIsTimeOutForceSubmit,
    setIsSecuritySubmit,
    setIsLoading,
    setQuizProgress,
    setCompleteQuestion,
  } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    if (phoneFocus) {
      setIsResponseError(false);
    }
  }, [phoneFocus]);

  useEffect(() => {
    const disableInspect = (e) => {
      // Disable right-click context menu
      if (e.type === "contextmenu") {
        e.preventDefault();
      }

      // Disable F12, F11, Ctrl+Shift+I, Ctrl+Shift+J, Ctrl+U, Alt+Tab, Alt+Ctrl+Tab
      if (e.type === "keydown") {
        if (
          e.key === "F12" || // F12
          e.key === "F11" || // F11
          (e.ctrlKey && e.shiftKey && e.key === "I") || // Ctrl+Shift+I
          (e.ctrlKey && e.shiftKey && e.key === "J") || // Ctrl+Shift+J
          (e.ctrlKey && e.key === "U") || // Ctrl+U
          (e.altKey && e.key === "Tab") || // Alt+Tab
          (e.altKey && e.ctrlKey && e.key === "Tab") // Alt+Ctrl+Tab
        ) {
          e.preventDefault();
        }
      }
    };

    // Add event listeners
    document.addEventListener("contextmenu", disableInspect);
    document.addEventListener("keydown", disableInspect);

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      document.removeEventListener("contextmenu", disableInspect);
      document.removeEventListener("keydown", disableInspect);
    };
  }, []);

  const handleFormSubmit = async (e) => {
    if (!isPhoneValid(phone) && quiztitle?.phonenumber) {
      setIsResponseError([true, "Please enter valid phone number"]);
      setIsInstructionShown(false);
      return;
    }

    const dataToUpdate = {
      name: formData?.firstname + " " + formData?.lastname,
      email: formData?.emailaddress,
      phonenumber: phone,
      address: formData?.address,
      password: formData?.password,
      quizid: quizId,
    };

    if (isFormSubmitted) {
      return;
    }
    setIsFormSubmitted(true);

    QuizApi.post(`quiz-initiate/`, dataToUpdate)
      .then((response) => {
        if (response?.data?.status === "error") {
          setIsResponseError([true, response?.data?.error]);
          setIsFormSubmitted(false);
          setIsInstructionShown(false);
          return;
        }
        if (response?.data?.quiz_end) {
          document.location.reload();
        }
        setTakeQuizQuestion(response.data?.first_question);
        setQuizGeneralSettings(response.data?.general_settings);
        setQuizBrowserSettings(response.data?.browser_settings);
        setQuizResult(response.data?.result_data);
        setCompleteQuestion(1);
        setQuizProgress(0);
        setIsTimeOutForceSubmit(false);
        setIsSecuritySubmit(false);
        setIsLoading(false);
        sessionStorage.setItem(
          "takeQuizQuestion",
          JSON.stringify(response.data?.first_question),
        );
        sessionStorage.setItem(
          "quizGeneralSettings",
          JSON.stringify(response.data?.general_settings),
        );
        sessionStorage.setItem(
          "quizBrowserSettings",
          JSON.stringify(response.data?.browser_settings),
        );
        sessionStorage.setItem(
          "quizResult",
          JSON.stringify(response.data?.result_data),
        );
        sessionStorage.setItem("completeQuestions", 1);
        sessionStorage.setItem("min", response.data?.general_settings?.minutes);
        sessionStorage.setItem("sec", 0);
        sessionStorage.setItem("quizStartTime", new Date().getTime());
        sessionStorage.setItem("quizProgress", 0);
        navigate(
          `/preview/${quizDetails?.quiztitle
            .replaceAll(" ", "-")
            .replaceAll("/", "-")}`,
        );

        if (isTabChangeAllowed.current && isBrowserSupportFullScreen.current) {
          const elem = document.documentElement;
          if (elem.requestFullscreen) {
            elem.requestFullscreen();
          } else if (elem.mozRequestFullScreen) {
            // Firefox
            elem.mozRequestFullScreen();
          } else if (elem.webkitRequestFullscreen) {
            // Chrome, Safari and Opera
            elem.webkitRequestFullscreen();
          } else if (elem.msRequestFullscreen) {
            // IE/Edge
            elem.msRequestFullscreen();
          }
          setIsFullscreenDialogOpen(false);
          setIsQuizSubmitted(false);
          setIsFirtRender(true);
        } else {
          if (
            document.fullscreenElement ||
            document.mozFullScreenElement ||
            document.webkitFullscreenElement ||
            document.msFullscreenElement
          ) {
            if (document.exitFullscreen) {
              document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
              // Firefox
              document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
              // Chrome, Safari and Opera
              document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
              // IE/Edge
              document.msExitFullscreen();
            }
          }
        }
      })
      .catch((error) => {
        setIsFormSubmitted(false);
        setIsInstructionShown(false);
        console.error("Error:", error);
        setError(error);
      });
  };

  const createScheduleMessage = (params_dict) => {
    if (params_dict?.quiz_schedule) {
      const startDate = format(
        new Date(params_dict?.start_date),
        "dd MMMM yyyy, hh:mm a",
      );
      let endDate = format(
        new Date(params_dict?.end_date),
        "dd MMMM yyyy, hh:mm a",
      );
      const sDate = new Date(params_dict?.start_date);
      const eDate = new Date(params_dict?.end_date);
      if (
        sDate.getDate() === eDate.getDate() &&
        sDate.getMonth() === eDate.getMonth() &&
        sDate.getFullYear() === eDate.getFullYear()
      ) {
        endDate = format(new Date(params_dict?.end_date), "hh:mm a");
      }

      let message = params_dict?.message.replace(
        `<start-time>`,
        `<b>${startDate}</b>\n`,
      );
      message = message.replace(`<end-time>`, `<b>${endDate}</b>`);
      return { message: message, quiz_schedule: true };
    } else if (params_dict?.quiz_end) {
      const endDate = format(
        new Date(params_dict?.end_date),
        "dd MMMM yyyy, hh:mm a",
      );
      let message = params_dict?.message?.replace(
        `<end-time>`,
        `<b>${endDate}</b>`,
      );
      return { message: message, quiz_end: true };
    }
  };

  const checkFullScreenSupport = () => {
    return (
      document.fullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.webkitFullscreenEnabled ||
      document.msFullscreenEnabled
    );
  };

  useEffect(() => {
    isBrowserSupportFullScreen.current = checkFullScreenSupport();

    async function loadQuizTitle() {
      try {
        const response = await QuizApi.get(`/quiztracking/${quizId}/`);
        console.log("Quiz title response:", response);
        setCustomScheduleMessage(
          createScheduleMessage(response?.data?.quiz_schedule),
        );
        setQuizTitle(response?.data?.quiz_tracking);
        setQuizDetails(response?.data?.quiz_details);
        setQuizsettings(response?.data?.quiz_general_settings);

        isTabChangeAllowed.current =
          response.data?.quiz_browser_settings?.switchingbrowsertabs;

        setLoading(false);
      } catch (error) {
        console.error("Error loading quiz data:", error);
        setError(error);
        setLoading(false);
      }
    }
    loadQuizTitle();
  }, []);

  const checkValidations = () => {
    if (!quiztitle?.ispasswordenabled && !quiztitle?.phonenumber) {
      setIsInstructionShown(true);
      return;
    }

    if (!isPhoneValid(phone) && quiztitle?.phonenumber) {
      setIsResponseError([true, "Please enter valid phone number"]);
      setIsInstructionShown(false);
      return;
    }

    if (quiztitle?.ispasswordenabled) {
      QuizApi.get(
        `quiz-initiate/?password=${formData.password}&quiz_id=${quizId}`,
      )
        .then((response) => {
          if (response?.data?.status) {
            setIsInstructionShown(true);
          } else {
            setIsResponseError([true, response?.data?.error]);
          }
        })
        .catch((error) => {
          setIsInstructionShown(false);
        });
    } else {
      setIsInstructionShown(true);
    }
  };

  const handleFormSubmitClick = (e) => {
    e.preventDefault();
    if (isTabChangeAllowed.current) {
      checkValidations();
    } else {
      handleFormSubmit();
    }
  };

  return (
    <>
      <div className="quizStartPageMain">
        {quizDetails?.quizstatus === "Draft" && (
          <>
            <div className="quiz-draft">
              <h3>This page is currently not available.</h3>
              <h3>Please contact whomever sent you this link.</h3>
            </div>
            <div className="bottomlogoimgdiv first">
              <Link className="nav-link" to="/">
                <img
                  src="/images/bottomlogo.png"
                  className="bottomlogoimg"
                  alt="quizsecure"
                ></img>
              </Link>
            </div>
          </>
        )}

        {quiztitle && quizDetails?.quizstatus === "Live" ? (
          <div className="takequiztittle">
            <div className="first-takequiztitle">
              {quizDetails?.quizlogo && (
                <img
                  alt=""
                  src={BACKEND_URL + "media/" + quizDetails?.quizlogo}
                ></img>
              )}
              <h1 className="quiztitle">
                {" "}
                {decodeHtmlEntities(quizDetails?.quiztitle)}
              </h1>

              {quizDetails?.titleimage && (
                <div className="titleimage">
                  <img
                    alt=""
                    src={BACKEND_URL + "media/" + quizDetails?.titleimage}
                  ></img>
                </div>
              )}
              {quizDetails?.quizdescription && (
                <div className="descriptioncontainer">
                  {quizDetails?.quizdescription}
                </div>
              )}
            </div>
            {customScheduleMessage?.quiz_schedule ||
            customScheduleMessage?.quiz_end ? (
              <div className="main-custom-message-outer">
                {customScheduleMessage?.quiz_schedule ? (
                  <>
                    <div className="quiz-info">
                      {quizSettings?.shownumberofquestions && (
                        <p className="total-questions">
                          {quizSettings?.selectnumberofquestion} Questions
                        </p>
                      )}
                      {quizSettings?.displaycountdown && (
                        <p className="total-time">
                          Duration: {quizSettings?.minutes} Minutes
                        </p>
                      )}
                    </div>
                    <hr />
                    <div className="main-custom-message">
                      <div className="body">
                        <ContentEditable
                          tagName="pre"
                          html={customScheduleMessage?.message}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="quiz-end">
                    <ContentEditable
                      tagName="pre"
                      html={customScheduleMessage?.message}
                      disabled={true}
                    />
                  </div>
                )}
              </div>
            ) : (
              <>
                <div
                  className="quiz-info"
                  ref={quizInfoRef}
                  style={{ width: "588px" }}
                >
                  <p className="total-questions">
                    {quizSettings?.selectnumberofquestion} Questions
                  </p>
                  <p className="total-time">
                    Duration: {quizSettings?.minutes} Minutes
                  </p>
                </div>
                <div className="Takequiz_container">
                  <div className="restoredowndetailcontainer">
                    <div className="detailcontainer" ref={detailContainerRef}>
                      <h2>Enter your Personal Information</h2>
                      <form
                        method="get"
                        id="quiztakerdetails"
                        autoComplete="off"
                        onSubmit={handleFormSubmitClick}
                      >
                        <table className="quiztitle-detailtable adjustspacinginquiztittle">
                          <tbody>
                            {quiztitle.name ? (
                              <tr>
                                <td>
                                  <label>Full name</label>
                                  <input
                                    type="text"
                                    name="firstname"
                                    id="fname"
                                    placeholder="First name"
                                    required
                                    value={formData.firstname}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        firstname: e.target.value,
                                      })
                                    }
                                  ></input>
                                </td>
                                <td>
                                  <br></br>
                                  <input
                                    type="text"
                                    name="lastname"
                                    id="lname"
                                    placeholder="Last name"
                                    required
                                    value={formData.lastname}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        lastname: e.target.value,
                                      })
                                    }
                                  ></input>
                                </td>
                              </tr>
                            ) : null}

                            {quiztitle.emailaddress ? (
                              <tr>
                                <td colSpan="2">
                                  <label>Email</label>
                                  <input
                                    type="email"
                                    name="email"
                                    id="emailAddress"
                                    placeholder="someone@example.com"
                                    required
                                    value={formData.emailAddress}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        emailaddress: e.target.value,
                                      })
                                    }
                                  ></input>
                                </td>
                              </tr>
                            ) : null}

                            {quiztitle.phonenumber ? (
                              <tr>
                                <td colSpan="2">
                                  <label>Phone Number</label>
                                  <PhoneInputInternational
                                    value={phone}
                                    setPhone={setPhone}
                                    phoneFocus={phoneFocus}
                                    setPhoneFocus={setPhoneFocus}
                                  />
                                  {isResponseError[0] &&
                                    isResponseError[1]?.includes(
                                      "phone number",
                                    ) && (
                                      <p
                                        className="error"
                                        style={{
                                          textAlign: "center",
                                          fontWeight: "normal",
                                          marginTop: "7px",
                                        }}
                                      >
                                        {isResponseError[1]}
                                      </p>
                                    )}
                                </td>
                              </tr>
                            ) : null}

                            {quiztitle.address ? (
                              <tr>
                                <td colSpan="2">
                                  <label>Address</label>
                                  <textarea
                                    name=""
                                    id="address"
                                    cols="30"
                                    rows="5"
                                    placeholder="Address"
                                    required
                                    value={formData.address}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        address: e.target.value,
                                      })
                                    }
                                  ></textarea>
                                </td>
                              </tr>
                            ) : null}

                            {quiztitle.collegename ? (
                              <tr>
                                <td colSpan="2">
                                  <label>College Name</label>
                                  <input
                                    type="text"
                                    name="collegename"
                                    id="cname"
                                    placeholder="College name"
                                    required
                                  ></input>
                                </td>
                              </tr>
                            ) : null}

                            {quiztitle.ispasswordenabled ? (
                              <tr>
                                <td colSpan="2">
                                  <label>Password</label>
                                  <span className="quiz-password">
                                    <input
                                      type={
                                        isPasswordShown ? "text" : "password"
                                      }
                                      name="password"
                                      id="password"
                                      placeholder="Password"
                                      required
                                      value={formData.password}
                                      onChange={(e) => {
                                        setIsResponseError([false, ""]);
                                        setFormData({
                                          ...formData,
                                          password: e.target.value,
                                        });
                                        if (
                                          e.target.value !==
                                            quiztitle.password &&
                                          e.target.value.length >=
                                            quiztitle?.password?.length - 1
                                        ) {
                                          setPasswordError("");
                                        } else {
                                          setPasswordError(""); // Reset the error message if they match
                                        }
                                      }}
                                      autoComplete="new-password"
                                    ></input>
                                    <span
                                      className="show-eye"
                                      onClick={() => {
                                        setIsPasswordShown(!isPasswordShown);
                                      }}
                                    >
                                      {isPasswordShown ? (
                                        <FontAwesomeIcon icon={faEyeSlash} />
                                      ) : (
                                        <FontAwesomeIcon icon={faEye} />
                                      )}
                                    </span>
                                  </span>
                                </td>
                              </tr>
                            ) : null}
                            {passwordError && (
                              <tr style={{ marginTop: "-10px" }}>
                                <td
                                  colSpan="2"
                                  id="phone_error"
                                  className="error-message"
                                >
                                  {passwordError}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                        {isTabChangeAllowed.current &&
                          !isBrowserSupportFullScreen.current && (
                            <p className="fullscreen_notsupport_message">
                              Your browser does not support the required
                              features for this quiz. Please use one of the
                              supported browsers: Chrome, Firefox, or Microsoft
                              Edge." This ensures users can switch to a
                              compatible browser if needed.
                            </p>
                          )}

                        {isResponseError[0] &&
                          (isResponseError[1]?.includes("Password") ||
                            isResponseError[1]?.includes(
                              "maximum number of allowed attempts",
                            )) && <p className="error">{isResponseError[1]}</p>}
                        <a>
                          <button
                            type="submit"
                            className="takequizstartbtn"
                            name="quiztitlebutton"
                            id="submit_button"
                            disabled={
                              isTabChangeAllowed.current &&
                              !isBrowserSupportFullScreen.current
                            }
                          >
                            {getHTMLtoText(quizDetails?.titlebutton)
                              ? getHTMLtoText(quizDetails?.titlebutton)
                              : "Start"}
                          </button>
                        </a>
                        <br></br>
                        <br></br>
                      </form>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        ) : (
          quizDetails?.quizstatus === "Live" && <p>Loading...</p>
        )}
        {isInstructionShown && (
          <div className="instructions">
            <div className="child-instruct">
              <h1>Important: Please Read Before Starting</h1>
              <div className="instruct">
                <p>
                  The quiz will open in full screen mode to help you focus. If
                  you exit full screen mode, switch tabs, or close the quiz
                  window before completing the quiz, your progress will be
                  submitted, and you will not be able to return.
                </p>
                <br />
                <p>
                  {" "}
                  If the quiz has a timer, it will end and submit your answers
                  when time runs out.
                </p>
                <br />
                <p>
                  Make sure you have a stable internet connection and are ready
                  to begin.
                </p>
                <br />
              </div>
              <button
                className="start-quiz-btn"
                onClick={() => {
                  handleFormSubmit();
                }}
              >
                Start the Quiz
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="bottomlogoimgdiv">
        <Link className="nav-link" to="/">
          <img
            src="/images/bottomlogo.png"
            className="bottomlogoimg"
            alt="quizsecure"
          ></img>
        </Link>
      </div>
    </>
  );
}

export default QuizTitle;
